
import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';
import SvgIcon from '@/components/utilities/SvgIcon.vue';

@Component({
    components:{
        SvgIcon
    }
})
export default class Toast extends Vue {
    @Prop() icon?: String; 
    @Prop() iconDark?: boolean; 
    @Prop() message!: String;
    @Prop() side?: String;
    @Prop() iconSmall?: boolean;
    @Prop() large?: boolean;

    get sideClass(){
        if (this.side === 'right') return 'toast-cont__message--left'
        else if (this.side === 'left') return 'toast-cont__message--right'
        else if (this.side === 'middle') return 'toast-cont__message--middle'
        else if (this.side === 'top') return 'toast-cont__message--top'
        else return 'toast-cont__message--left'
    }

}
