import { ExchangeRangeRate, ExchangeRate } from "@/modules/exchange/interfaces/exchangeRate.interface";
import { RangeRate } from "@/modules/rates/interfaces/rangeRate.interface";
import { RateType } from "@/modules/rates/interfaces/rateType.interface";
import { MANUAL_RATES, RATE_CATEGORIES, RATE_OPERATIONS, RATE_TYPES } from "../constants/rate.constants";
import { Rate } from "../interfaces/rate.interface";

export function validateRemittanceRate(currencyId: number,destinyCurrencyId: number,rateTypes: RateType[],ranges: RangeRate[], ammount: number) : {rateType: RateType | null, rangeRate: RangeRate | null}{
    let possibleRanges : RangeRate[]  = ranges.filter(el =>{
        if (el.id_currency_origin === currencyId && el.id_currency_destiny === destinyCurrencyId){
            if (ammount >= el.lower_limit) return el
        }
    })
    var values = possibleRanges.map(val => val.lower_limit);
    var max = Math.max.apply(null, values);
    let currentRange = possibleRanges.find(el =>{
        if (el.lower_limit === max) return el
    })
    if (currentRange === undefined) return {rateType: null, rangeRate: null}
    return {rateType: rateTypes.find(el =>{
        if (el.idRateType === currentRange!.id_rate_type) return el
    })!, rangeRate: currentRange}
}

// que nos de el menor numero y que se encuentre en el rngo
export function validateRemittanceRateFromDestiny(currencyId: number,destinyCurrencyId: number,rateTypes: RateType[],ranges: RangeRate[], amount: number, rates: Rate[]) : {rateType: RateType | null, rangeRate: RangeRate | null}{
    let possibleRanges : RangeRate[]  = ranges.filter(el =>{
        if (el.id_currency_origin === currencyId && el.id_currency_destiny === destinyCurrencyId) return el
    })
    let currentRange = possibleRanges.find(el =>{
        const rate = rates.find(r => r.id_rate_type === el.id_rate_type);
        let val = 0 ;
        if (rate) val = reverseCurrencyConvertion(rate,amount)
        console.log(el, val)
        if ((el.upper_limit && val >= el.lower_limit && val<= el.upper_limit)
            ||
            !el.upper_limit && val >= el.lower_limit)  return el
    })
    console.log('currentRnge,',currentRange)
    if (currentRange === undefined) return {rateType: null, rangeRate: null}
    return {rateType: rateTypes.find(el =>{
        if (el.idRateType === currentRange!.id_rate_type) return el
    })!, rangeRate: currentRange}
}

export function currencyConvertion(rate: Rate, totalOrigin: number){
    if (rate.operation === RATE_OPERATIONS.MULTIPLICATION)
        return parseFloat((totalOrigin * rate.rate_factor).toFixed(2))
    else if (rate.operation === RATE_OPERATIONS.DIVISION)
        return parseFloat((totalOrigin / rate.rate_factor).toFixed(2))
    else return 0
}

export function reverseCurrencyConvertion(rate: Rate, totalDestiny: number){
    if (rate.operation === RATE_OPERATIONS.MULTIPLICATION){
        return parseFloat((totalDestiny / rate.rate_factor).toFixed(2))
    }
    else if (rate.operation === RATE_OPERATIONS.DIVISION){
        return parseFloat((totalDestiny * rate.rate_factor).toFixed(2))
    }
    else {
        return 0
    }
}

export function exchangeCurrencyConvertion(rate: ExchangeRate, totalOrigin: number,decimalsQuant?: number){
    let decimals = 2;
    if (decimalsQuant)  decimals = decimalsQuant;
    if (rate.operation === RATE_OPERATIONS.MULTIPLICATION)
        return parseFloat((totalOrigin * rate.rate_factor).toFixed(decimals))
    else if (rate.operation === RATE_OPERATIONS.DIVISION)
        return parseFloat((totalOrigin / rate.rate_factor).toFixed(decimals))
    else return 0
}

export function reverseExchangeCurrencyConvertion(rate: ExchangeRate, totalDestiny: number,decimalsQuant?: number){
    let decimals = 2;
    if (decimalsQuant) decimals=decimalsQuant;
    if (rate.operation === RATE_OPERATIONS.MULTIPLICATION){
        return parseFloat((totalDestiny / rate.rate_factor).toFixed(decimals))
    }
    else if (rate.operation === RATE_OPERATIONS.DIVISION){
        return parseFloat((totalDestiny * rate.rate_factor).toFixed(decimals))
    }
    else {
        return 0
    }
}

export function findSpecificRate(category: string,rates: Array<any>,rateTypeId: number){
    if (category === RATE_CATEGORIES.AUTOMATIC || category === RATE_CATEGORIES.MANUAL)
         return rates.find(el=> { 
             if (el.id_rate_type === rateTypeId
                 ) return el
         })
 }

export function findDefaultRate(category: string,rates: Array<any>,){
    if (category === RATE_CATEGORIES.AUTOMATIC || category === RATE_CATEGORIES.MANUAL)
        return rates.find(el=> { 
            if (el.rate_type_name === 'Normal' 
                ) return el
        })
    else if (category === RATE_CATEGORIES.VIP || category === RATE_CATEGORIES.SPECIAL)
        return rates
}

export function manualRateNames(name: string){
    if (name === MANUAL_RATES.VIPF) return {name:'VIP Fidelidad', type: 'Mejorada'}
    else if (name === MANUAL_RATES.FIRST_OP) return {name:'Primera Operación', type: 'Mejorada'}
    else if (name === MANUAL_RATES.BIRTHDAY) return {name:'Cumpleaños', type: 'Mejorada'}
    else if (name === MANUAL_RATES.NORMAL || name === MANUAL_RATES.PREFERENTIAL || name === MANUAL_RATES.WHOLESALER) return {name:name, type: 'Regular'}
}

export function findDefaultRateType(rateTypes: RateType[]){
    return rateTypes.find(el=> el.name === RATE_TYPES.NORMAL)
}

export function validateExchangeRate(routeId: number,rateTypes: RateType[],ranges: ExchangeRangeRate[], ammount: number) : {rateType: RateType | null, rangeRate: ExchangeRangeRate | null}{
    let possibleRanges : ExchangeRangeRate[]  = ranges.filter(el =>{
        if (el.id_operation_route === routeId){
            if (ammount >= el.lower_limit) return el
        }
    })
    var values = possibleRanges.map(val => val.lower_limit);
    var max = Math.max.apply(null, values);
    let currentRange = possibleRanges.find(el =>{
        if (el.lower_limit === max) return el
    })
    if (currentRange === undefined) return {rateType: null, rangeRate: null}
    return {rateType: rateTypes.find(el =>{
        if (el.idRateType === currentRange!.id_rate_type) return el
    })!, rangeRate: currentRange}
}
