
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { Country } from '@/modules/countries/interfaces/countries.interface';
/* INTERFACES */
/* CONSTANTS */
/* FUNCTIONS */


@Component({
    components: {
        SvgIcon
    }
})
export default class ConfirmationModal extends Vue {
    @Prop() showModal!: boolean;
    @Prop() frequentDestinations!: Country[];
    show: boolean = false;

    /* MODAL */
    @Watch('showModal')
     renderModal(){
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean){
        this.$emit('changeModalStatus', newVal)
    }
    /* END MODAL */


}
    
