export function getFiveColors(j: number){
    let quantity = 5;
    let ammount = 1;
    if (j<=quantity) return j;
    else {
        for (let i = 1; i <= j; i++) {
            if (i % quantity === 0) ammount = 0;
            else ammount++;
        }
        return ammount;
    }
}