
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/* INTERFACES */
import {Remittance} from '@/modules/remittance/interfaces/remittance.interface'
/* CONSTANTS */
import { REMITTANCE_PUBLIC_STATUS } from '../constants/remittance.constants';
/* FUNCTIONS */
import { epochToDateAndHour } from '@/utils/date-functions';
import { remittanceBadgeColor } from '../functions/remittance.functions';
import { reverseCurrencyConvertion } from '@/modules/rates/functions/rates.functions';
import { Rate } from '@/modules/rates/interfaces/rate.interface';
import { hideEmail, hideSomeCharacters } from '@/utils/global-functions';

@Component({
    components: {
        SvgIcon
    }
})
export default class RemittanceDetailPopup extends Vue {
    @Prop() showModal!: boolean;
    @Prop() remittance!: Remittance;
    show: boolean = false;
    publicStatus =  REMITTANCE_PUBLIC_STATUS;

    /* MODAL */
    @Watch('showModal')
     renderModal(){
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean){
        this.$emit('changeModalStatus', newVal)
    }
    /* END MODAL */

    getRemittanceBadgeColor(status: string){
       return remittanceBadgeColor(status)
    }

    dateAndHour(epoch: number){
      return epochToDateAndHour(epoch)
    }

    currencyConvertion(amount: number, rate: Rate){
        return this.$options.filters.currency(reverseCurrencyConvertion(rate,amount))
    }

    sendConsultMessage(){
        this.$router.push({name: 'Chat', params:{message: `¡Hola! Quiero realizar una consulta con respecto a la operación ${this.remittance.id_remittance_pub}, realizada el día ${this.dateAndHour(this.remittance.date_created)}`}}).catch();
    }

    hideEmail(email: string){
       return hideEmail(email)
    }

    hideSomeCharacters(text: string,init:number,end: number){
        return hideSomeCharacters(text,init,end)
    }

}
    
