
import { Component, Vue, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import RemittanceDetailPopup from '@/modules/remittance/components/RemittanceDetailPopup.vue';
import DataCard from '@/components/utilities/DataCard.vue';
import Toast from '@/components/utilities/Toast.vue';
import RemittanceByCountryPopup from '@/modules/remittance/components/RemittanceByCountryPopup.vue'
import SendRemittancePopup from '@/modules/remittance/components/sendRemittance/popups/SendRemittancePopup.vue'
import RemittanceTypePopup from '@/modules/remittance/components/sendRemittance/popups/RemittanceTypePopup.vue'
import InfoModal from '@/components/utilities/InfoModal.vue';
import NoRemittancePopup from '@/modules/verificationLevels/components/NoRemittancePopup.vue';
/* CONSTANTS */
import {OPERATIONS_ROUTE_TYPES} from '@/modules/remittance/constants/operations.constants';
import { REMITTANCE_PUBLIC_STATUS, REMITTANCE_TYPES } from '../constants/remittance.constants';
import { MONTHS_VALUES_ARRAY } from '@/utils/date.constants';
/* FUNCTIONS */
import { capitalize, showToast } from '@/utils/global-functions';
import { deltaDate, epochToDateAndHour, epochToDDMMYYYY, formatDate, getLastNMonthEpochDate, isLessOrEqualToOneYear, usToSpanishFormat } from '@/utils/date-functions';
import { remittanceBadgeColor } from '../functions/remittance.functions';
import { searchFilter } from '@/utils/filter.functions'
/* STORE */
import { auth, beneficiaries, countries, notifications, remittance,currencies, rates } from '@/store/namespaces';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods';
import AuthMethods from '@/store/auth/methods/auth.methods';
import NotificationsMethods from '@/store/notifications/methods/verificationLevels.methods';
import BeneficiariesMethods from '@/store/beneficiaries/methods/beneficiaries.methods';
import CountriesMethods from '@/store/countries/methods/countries.methods';
import CurrenciesMethods from '@/store/currencies/methods/currencies.methods';
import RatesMethods from '@/store/rates/methods/rates.methods';
/* INTERFACES */
import { Remittance } from '../interfaces/remittance.interface';
import { User } from '@/modules/auth/interfaces/user.interface';
import { CALENTAR_LABELS } from '@/utils/bootstrap.constants';
import { getFiveColors } from '@/modules/charts/functions/chart.functions';
import { Notif } from '@/modules/notifications/interfaces/notification.interface';
import { Country, CountryCurrency } from '@/modules/countries/interfaces/countries.interface';
import { RemittanceAcum } from '../interfaces/reports.interface';
import { FrequentBeneficiary, TopFrequentBeneficiary } from '@/modules/beneficiaries/interfaces/beneficiaries.interfaces';
/* LIBRARIES */
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { PreRemittance } from '../interfaces/preRemittance.interface';

@Component({
    components: {
        SvgIcon,
        RemittanceDetailPopup,
        RemittanceByCountryPopup,
        DataCard,
        MonthPicker,
        MonthPickerInput,
        Toast,
        VueperSlides, 
        VueperSlide,
        SendRemittancePopup,
        RemittanceTypePopup,
        InfoModal,
        NoRemittancePopup
    },
})
export default class Operations extends Vue {
    loading: boolean = true;
    publicStatus =  REMITTANCE_PUBLIC_STATUS;
    lastThreeMonthsDays = true;
    dateRangeFilterPopup = false;
    dateSelectionActive = {
        destiny: false,
        origin: false
    };
    tabIndex = 0;
    remittanceDetailModal = {
        show: false,
        remittance: null
    };
    remittancesByCountriesModal = false
    calendarLabels= CALENTAR_LABELS;
    completedRemittances = 0
    /* DATE FILTERS */
    currentDestinyDate = {val: '',epoch: 0,formatted: ''};
    currentOriginDate = {val: '',epoch: 0,formatted: ''};
    destinyDate = {val: '',epoch: 0,formatted: ''};
    originDate = {val: '',epoch: 0,formatted: ''};
    /* TABLE */
    perPage : number = 6;
    currentPage : number = 1;
    textFilter : string = '';
    isBusy : boolean = false;
    sortBy : string = 'date_created';
    sortDesc : boolean = true;
    fields = [
        {label: 'Nº', key: 'id_remittance_pub', sortable: true},
        {label: 'Fecha', key: 'date_created', sortable: true},
        // {label: 'Fecha Cierre', key: 'date_closed', sortable: true},
        {label: 'Origen/Destino', key: 'origin_iso_code', sortable: false},
        {label: 'Beneficiario(s)', key: 'beneficiaries', sortable: true},
        {label: 'Monto Enviado', key: 'total_origin_amount', sortable: true},
        {label: 'Monto Recibido', key: 'total_destiny_amount', sortable: true},
        {label: 'Tasa Aplicada', key: 'rate', sortable: true},
        {label: 'Estatus', key: 'public_status', sortable: true},
        {label: 'Detalle', key: 'actions', sortable: false},
    ]
    currentBeneficiary : FrequentBeneficiary = null
    currentCountryCurrency: CountryCurrency = null
    /* MODALS */
    remittanceTypeModal = false;
    sendRemittanceModal = false;
    remittanceInProcessInfoModal= {
        title: 'Usted ha iniciado una operación en los pasados 15 minutos y no ha anexado su comprobante de pago. Si desea completar la operación, presione "Completar", si desea cancelarla, presione "Cancelar".',
        open: false
    }
    deleteConfirmationModal = false;
    /* END MODALS */
    /* CHARTS */
    chart={
        options:{
            xaxis:{categories: null,labels:{show:true,style:{fontSize: '10px',fontFamily: 'Montserrat, sans-serif'}}},
            yaxis:{labels:{style:{fontSize: '10px',fontFamily: 'Montserrat, sans-serif'}},title:{text:`Monto enviado en `,style:{fontSize: '10px',fontFamily: 'Montserrat, sans-serif',fontWeight:400}}},
            chart:{toolbar: {show: false}},
            noData:{text: 'No se encontraron resultados', style: {fontSize: '16px',fontFamily: 'Montserrat, sans-serif'}},
            fill:{colors:['#E27A3F']},
            dataLabels: {formatter: function (val, opts) {return val}},
            tooltip: {y: {formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {return value}}}
        },
        series:[{name: 'Monto enviado',data: []}]
    }
    remittanceByMonthChart={}
    remittanceByMonthChartSeries= [{name: 'Cantidad de operaciones',data: []}]
    remittanceByMonthChartOptions= {
            chart: {height: 200,type: 'area',toolbar:{show: false}},
            dataLabels: {enabled: false},
            stroke: {curve: 'smooth'},
            yaxis:{labels:{show:false}},
            grid:{show:false},
            fill:{colors:['#E27A3F']},
            colors: ['#E27A3F'],
            noData:{text: 'No se encontraron resultados', style: {fontSize: '16px',fontFamily: 'Montserrat, sans-serif'}},
            xaxis: {type: 'category',labels:{show: false},categories: []},
            tooltip: {style: {fontSize: '12px',fontFamily: 'Montserrat, sans-serif'},},
    }
    chartInUsd=false;
    topBeneficiariesSeries= []
    topBeneficiariesOptions= {chart: {type: 'donut',},legend:{show: false,},dataLabels: {enabled: false},labels: [],colors:['#334D5C','#45B29D','#EFC94C','#E27A3F','#DF4949'],}
    remittanceByStatusSeries= []
    remittanceByStatusOptions= {chart: {type: 'donut',},legend:{show: false,},dataLabels: {enabled: false},labels: [],colors:['#334D5C','#45B29D','#EFC94C','#E27A3F','#DF4949'],}
    originSelectedDate= {
        from: null,
        to: null,
        month: null,
        year: null,
        selectedYear: null,
        monthIndex: null,
        epoch: null
	}
    destinySelectedDate= {
        from: null,
        to: null,
        month: null,
        year: null,
        selectedYear: null,
        monthIndex: null,
        epoch: null
	}
    monthConstants = MONTHS_VALUES_ARRAY;
    $refs: any ={}
    noRemittanceModal = false;

    async mounted(){
        this.loading = true;
        try{
            // if (this.$route.query.tipo !== OPERATIONS_ROUTE_TYPES.REMITTANCES 
            //     &&
            // this.$route.query.tipo !== OPERATIONS_ROUTE_TYPES.EXCHANGES){
            //     this.$router.push({name: 'NotFound'}).catch();
            // }
            if (!await this.fetchRemittancesByStatus(this.userData.email_user)) throw new Error();
            if (this.totalRemittances >0){
                this.currentDestinyDate.epoch = Math.trunc(Date.now() / 1000);
                this.currentDestinyDate.formatted = epochToDDMMYYYY(this.currentDestinyDate.epoch);
                this.currentOriginDate.epoch = getLastNMonthEpochDate(3);
                this.currentOriginDate.formatted = epochToDDMMYYYY(this.currentOriginDate.epoch);
                this.originDate = Object.assign({},this.currentOriginDate);
                this.destinyDate = Object.assign({},this.currentDestinyDate);
                if (!await this.fetchRemittances(
                            {
                            email_user: this.userData.email_user, 
                            limit: null, 
                            start_date: this.currentOriginDate.epoch,
                            end_date: this.currentDestinyDate.epoch,
                            mode: null
                            }) ||
                    !await this.fetchFrequentBeneficiaries({email_user: this.userData.email_user}) ||
                    !await this.fetchTopFrequentBeneficiaries(this.userData.email_user) ||
                    !await this.fetchCountriesCurrencies(this.userData.email_user) ||
                    !await this.fetchTotalSentByCurrency(this.userData.email_user) ||
                    !await this.fetchFrequentDestinys(this.userData.email_user) ||
                    !await this.fetchRatesTakenAdvantageOf(this.userData.email_user) ||
                    !await this.fetchRemittanceQuantityByMonth({email_user: this.userData.email_user, month: null})
                    )
                    throw new Error();
                else {
                    let completedRemittances = this.remittancesByStatus.find(el=> el.public_status === REMITTANCE_PUBLIC_STATUS.FINISHED)
                    if (this.userRemittancesCountriesCurrencies.length >0 && completedRemittances && completedRemittances.total > 0 ){
                        this.currentCountryCurrency = this.userRemittancesCountriesCurrencies[0];
                        this.getDefaultChartDatesInEpoch();
                        if (!await this.fetchRemittanceTotalsByMonthWithFilters()) throw new Error()
                        else this.setRemittanceAcumByMonthChartData();
                        this.setBeneficiariesChart();
                        this.setRemittancesByStatusChart();
                        this.setRemittancesQuantityByMonthChart()
                    }
                    if (completedRemittances) this.completedRemittances = completedRemittances.total;
                    else this.completedRemittances = 0;
                }
                
            }
            this.loading = false;
        }catch(e){
           this.$router.push({name: 'ErrorPage'})
        }
    }

    checkLastThreeMonths(){
        this.lastThreeMonthsDays = !this.lastThreeMonthsDays; 
        if (this.lastThreeMonthsDays)
            this.resetDates();
    }

    async fetchRemittanceTotalsByMonthWithFilters(){
        return  await this.fetchRemittanceTotalsByMonth({
                email_user: this.userData.email_user,
                params:{
                    from_date: this.originSelectedDate.epoch,
                    to_date: this.destinySelectedDate.epoch,
                    id_country: this.currentCountryCurrency.id_country,
                    id_currency: this.currentCountryCurrency.id_currency,
                    id_beneficiary: this.currentBeneficiary === null ? this.currentBeneficiary : this.currentBeneficiary.id_beneficiary
                }
        })
    }

    setBeneficiariesChart(){
        this.topBeneficiariesSeries= this.topFrequentBeneficiaries.map(el=> el.count)
        let labels = this.topFrequentBeneficiaries.map(el=> el.owner_name)
        this.topBeneficiariesOptions= {chart: {type: 'donut',},legend:{show: false,},dataLabels: {enabled: false},
            colors:['#334D5C','#45B29D','#EFC94C','#E27A3F','#DF4949'],
            labels: labels}
    }

    setRemittancesByStatusChart(){
        this.remittanceByStatusSeries= this.remittancesByStatus.map(el=> el.total)
        let labels = this.remittancesByStatus.map(el=> el.public_status)
        this.remittanceByStatusOptions= {chart: {type: 'donut',},legend:{show: false,},dataLabels: {enabled: false},
            colors:['#334D5C','#45B29D','#EFC94C','#E27A3F','#DF4949'],
            labels: labels}
    }

    setRemittancesQuantityByMonthChart(){
        let xaxis = this.remittanceQuantityByMonth.map(serie => {
           let month = this.monthConstants.find(el=> el.monthIndex === serie.month)
           return month.spanishAcronym+`-${serie.year}`
        });
        this.remittanceByMonthChartSeries=[{data:this.remittanceQuantityByMonthSeries,name: 'Operaciones realizadas'}]
        this.remittanceByMonthChartOptions={
                chart: {height: 200,type: 'area',toolbar:{show: false}},
                dataLabels: {enabled: false},
                stroke: {curve: 'smooth'},
                yaxis:{labels:{show:false}},
                grid:{show:false},
                fill:{colors:['#E27A3F']},
                colors: ['#E27A3F'],
                noData:{text: 'No se encontraron resultados', style: {fontSize: '16px',fontFamily: 'Montserrat, sans-serif'}},
                xaxis: {type: 'category',labels:{show: false},categories: xaxis},
                tooltip: {style: {fontSize: '12px',fontFamily: 'Montserrat, sans-serif'},},
        }
    }

    get remittanceQuantityByMonthSeries(){
        return this.remittanceQuantityByMonth.map(serie => serie.total);
    }

    get topBeneficiariesChart(){
       return {show:true,type:'pie',options: this.topBeneficiariesOptions, series: this.topBeneficiariesSeries}
    }

    get remittanceByStatusChart(){
       return {show:true,type:'pie',options: this.remittanceByStatusOptions, series: this.remittanceByStatusSeries}
    }

    get firstFrequentsDestinations(){
        return this.frequentDestinations.splice(0,3)
    }

    async setRemittanceAcumByMonthChartData(){
        let xaxis = this.remittanceTotalByMonth.map(serie => {
           let month = this.monthConstants.find(el=> el.monthIndex === serie.month)
           return month.spanishAcronym+`-${serie.year}`
        });
        this.chart.series=[{data:this.remittanceTotalByMonthSeries,name: 'Monto enviado'}]
        this.chart.options={
            xaxis:{categories: xaxis,labels:{show: this.remittanceTotalByMonth.length > 0 ? true : false,style:{fontSize: '10px',fontFamily: 'Montserrat, sans-serif'}}},
            yaxis:{labels:{style:{fontSize: '10px',fontFamily: 'Montserrat, sans-serif'}},title:{text:`Monto enviado en ${this.chartInUsd ? 'USD' : this.currentCountryCurrency.iso_cod}`,style:{fontSize: '10px',fontFamily: 'Montserrat, sans-serif',fontWeight:400}}},
            chart:{toolbar: {show: false}},
            noData:{text: 'No se encontraron resultados', style: {fontSize: '16px',fontFamily: 'Montserrat, sans-serif'}},
            fill:{colors:['#E27A3F']},
            dataLabels: {formatter: function (val, opts) {var str = val.toString().split('.');if (str[0].length >= 4) {str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1.');}if (str[1]) return str.join(','); else return str[0]+',00'}},
            tooltip: {y: {formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {var str = value.toString().split('.');if (str[0].length >= 4) {str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1.');}if (str[1]) return str.join(','); else return str[0]+',00'}}}
        }
    }

    async selectBeneficiary(beneficiary:FrequentBeneficiary){
        this.currentBeneficiary = beneficiary;
        if (!await this.fetchRemittanceTotalsByMonthWithFilters()) this.$router.push({name:'ErrorPage'}).catch();
        else this.setRemittanceAcumByMonthChartData();
    }

    async selectCountryCurrency(countryCurrency: CountryCurrency){
        this.currentCountryCurrency = countryCurrency;
        if (!await this.fetchRemittanceTotalsByMonthWithFilters()) this.$router.push({name:'ErrorPage'}).catch();
        else this.setRemittanceAcumByMonthChartData();
    }

    changeChartView(){
        this.chartInUsd = !this.chartInUsd;
        this.setRemittanceAcumByMonthChartData();
    }

    get remittanceTotalByMonthSeries(){
            if (!this.chartInUsd)
                return this.remittanceTotalByMonth.map(serie => serie.origin_sum.toFixed(2));
            else return this.remittanceTotalByMonth.map(serie => serie.dollar_sum.toFixed(2));
    }

    async getStartDate(date) {
		this.originSelectedDate = date
        this.originSelectedDate.epoch= new Date(this.originSelectedDate.selectedYear,this.originSelectedDate.monthIndex - 1,1).getTime() / 1000
        // if (!isLessOrEqualToOneYear(this.destinySelectedDate.monthIndex,this.destinySelectedDate.selectedYear,this.originSelectedDate.monthIndex,this.originSelectedDate.selectedYear)){
        //     window.alert('NO CHE PUEDE')
        // }
        if (!await this.fetchRemittanceTotalsByMonthWithFilters()) this.$router.push({name:'ErrorPage'}).catch();
        else this.setRemittanceAcumByMonthChartData();
    }

    async getEndDate(date) {
		this.destinySelectedDate = date
        this.destinySelectedDate.epoch= new Date(this.destinySelectedDate.selectedYear,this.destinySelectedDate.monthIndex - 1,1).getTime() / 1000
        // if (!isLessOrEqualToOneYear(this.originSelectedDate.monthIndex,this.originSelectedDate.selectedYear,this.destinySelectedDate.monthIndex,this.destinySelectedDate.selectedYear)){
        //     this.originSelectedDate.epoch= deltaDate(new Date(), 0, 0, -1).getTime() / 1000
        //     this.originSelectedDate.selectedYear = deltaDate(new Date(), 0, 0, -1).getFullYear()
        //     this.originSelectedDate.monthIndex = deltaDate(new Date(), 0, 0, -1).getMonth()
        //     showToast('La fecha de inicio ha sido modificada. El rango de fecha debe corresponder máximo a un año','warning')
        // }
        if (!await this.fetchRemittanceTotalsByMonthWithFilters()) this.$router.push({name:'ErrorPage'}).catch();
        else this.setRemittanceAcumByMonthChartData();
    }

    get minDate(){
        return deltaDate(new Date(), 0, -1, -3);
    }

    getDefaultChartDatesInEpoch(){
        this.originSelectedDate.epoch = this.startDate.getTime() / 1000;
        this.originSelectedDate.monthIndex = this.startDate.getMonth();
        this.originSelectedDate.selectedYear = this.startDate.getFullYear();
        this.destinySelectedDate.epoch = this.endDate.getTime() / 1000;
        this.destinySelectedDate.monthIndex = this.endDate.getMonth();
        this.destinySelectedDate.selectedYear = this.endDate.getFullYear();
    }

    async getRemittanceByFilters(){
        this.dateRangeFilterPopup = false;
        this.isBusy = true;
        let response= await this.fetchRemittances(
                {email_user: this.userData.email_user, 
                limit: null, 
                start_date: this.currentOriginDate.epoch,
                end_date: this.currentDestinyDate.epoch,
                mode: null

        })
        this.isBusy = false;
        return response
    }

    get criteria() {
        return this.textFilter.trim().toLowerCase();
    }

    get availableOptions() {
        const criteria = this.criteria
        if (criteria) {
          return searchFilter(this.remittances,['public_status','origin_currency','destiny_currency','origin_iso_code','destiny_iso_code','id_remittance_pub','total_origin_amount','total_destiny_amount','rate.rate_factor','beneficiaries','benef_name','benef_account','benef_identification'],this.criteria);
        }
        return this.remittances
    }

    get startDate(){
        return deltaDate(new Date(), 0, -3, 0)
    }

    get endDate(){
        return new Date()
    }


    @Watch('dateRangeFilterPopup')
    openDateFilterModal(newVal: boolean){
        if (newVal){
            this.originDate = Object.assign({},this.currentOriginDate);
            this.destinyDate = Object.assign({},this.currentDestinyDate);
        }
    }

    @Watch('originDate.val')
    changeDateFormat(val: string){
        if (val !== ''){
            this.dateSelectionActive.origin = false;
            this.originDate.formatted = formatDate(val);
            this.originDate.epoch = new Date(usToSpanishFormat(this.originDate.formatted)).getTime() /1000;
        }
        this.lastThreeMonthsDays = false;
    }

    @Watch('destinyDate.val')
    changeDestinyDateFormat(val: string){
        if (val !== ''){
            this.dateSelectionActive.destiny = false;
            this.destinyDate.formatted = formatDate(val);
            this.destinyDate.epoch = new Date(usToSpanishFormat(this.destinyDate.formatted)).getTime() /1000;
        }
        this.lastThreeMonthsDays = false;
    }

    resetDates(){
        this.currentOriginDate.val = ''
        this.currentDestinyDate.val = ''
        this.currentDestinyDate.epoch = Math.trunc(Date.now() / 1000);
        this.currentDestinyDate.formatted = epochToDDMMYYYY(this.currentDestinyDate.epoch);
        this.currentOriginDate.epoch = getLastNMonthEpochDate(3);
        this.currentOriginDate.formatted = epochToDDMMYYYY(this.currentOriginDate.epoch);
        this.destinyDate.val = this.currentDestinyDate.val;this.destinyDate.epoch = this.currentDestinyDate.epoch;this.destinyDate.formatted = this.currentDestinyDate.formatted;
        this.originDate.val = this.currentOriginDate.val;this.originDate.epoch = this.currentOriginDate.epoch;this.originDate.formatted = this.currentOriginDate.formatted;
    }

    dateAndHour(epoch: number){
      return epochToDateAndHour(epoch)
    }

    getRemittanceBadgeColor(status: string){
       return remittanceBadgeColor(status)
    }

    getStatusName(key: string): string{
        switch(key) {
            case 'rejected':
                return 'Rechazado'
            case 'toBeChecked':
                return 'Por verificar'
            case 'sent':
                return 'Enviado'
            case 'inProgress':
                return 'En proceso'
        }
    }

    /* MODAL */
    remittanceDetailModalStatus(newVal: boolean){
        this.remittanceDetailModal.show = newVal;
    }
    remittancesByCountriesModalStatus(newVal: boolean){
        this.remittancesByCountriesModal = newVal;
    }
    remittanceTypeModalStatus(newVal: boolean){
        this.remittanceTypeModal = newVal
    }
    openSecondModal(){
        // this.sendRemittanceModal = true;
        if (this.$route.name !== 'SendRemittance' || (this.$route.name === 'SendRemittance' && this.$route.params.modo !== REMITTANCE_TYPES.NO_CAPTURE))
            this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
    }
    sendRemittanceModalStatus(newVal: boolean) {
        this.sendRemittanceModal = newVal;
    }
    deleteConfirmationModalStatus(newVal: boolean){
        this.deleteConfirmationModal = newVal;
    }
    remittanceInProcessModalStatus(newVal: boolean){
        this.remittanceInProcessInfoModal.open = newVal;
    }
    cancelPreRemittanceConfirmation(){
        this.remittanceInProcessInfoModal.open = false;
        this.deleteConfirmationModal = true;
    }
    noRemittanceModalStatus(newVal: boolean){
        this.noRemittanceModal = newVal;
    }

    sendToRemittance(){
        if (this.hasMigratedException) showToast('Esperando aprobación para verificación de usuario','warning')
        else if (((this.userData.id_verif_level === 1 && this.userData.verif_level_apb === true) || this.userData.id_verif_level > 1)){
            if (!this.preRemittance.id_pre_remittance){
            // this.remittanceTypeModal = true;
            this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
            }
            else if (this.preRemittance.active && !this.preRemittance.was_expired)
            {
                this.remittanceInProcessInfoModal.open = true;
            }
            else
            this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.NO_CAPTURE}`}).catch();
            //  this.remittanceTypeModal = true;
        }else this.noRemittanceModal = true;
    }

    async cancelPreRemitanceConfirmed(){
        this.deleteConfirmationModal = false;
        this.$refs.loader.showLoader();
        if (await this.cancelPreRemitance(this.preRemittance.id_pre_remittance))
            showToast('Operación cancelda exitosamente','success')
        else showToast('Ha ocurrido un error cancelando la operación','success')
        this.$refs.loader.hideLoader();
    }
    /* END MODAL */

    /* CHARTS */
    getBadgeColor(j: number): number{
       return getFiveColors(j)
    }
    /* END CHARTS */

    /* PRE REMITTANCE */
    completePreRemittance(){
        this.$router.push({path:`/enviar-dinero/${REMITTANCE_TYPES.WITH_CAPTURE}`}).catch();
    }
    /* END PRE REMITTANCE */

    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    @auth.Getter(AuthMethods.getters.HAS_MIGRATED_EXCEPTION)
    hasMigratedException!: boolean;
    /* REMITTANCES */
    @remittance.Action(RemittanceMethods.actions.FETCH_REMITTANCES)
    fetchRemittances!:(data: {email_user: string, limit: number, start_date: number, end_date: number, mode: string}) => Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_REMITTANCES)
    remittances!: Remittance[];
    @remittance.Action(RemittanceMethods.actions.FETCH_REMITTANCE_TOTALS_BY_MONTH)
    fetchRemittanceTotalsByMonth!:(data:{email_user: string, params: any})=> Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_REMITTANCE_TOTALS_BY_MONTH)
    remittanceTotalByMonth!: RemittanceAcum[]
    @remittance.Action(RemittanceMethods.actions.FETCH_REMITTANCES_BY_STATUS)
    fetchRemittancesByStatus!:(email_user: string)=> Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_REMITTANCES_BY_STATUS)
    remittancesByStatus!: {total: number, public_status: string}[]
    @remittance.Getter(RemittanceMethods.getters.GET_TOTAL_REMITTANCES)
    totalRemittances!: number
    @remittance.Action(RemittanceMethods.actions.FETCH_REMITTANCES_QUANTITY_BY_MONTH)
    fetchRemittanceQuantityByMonth!:(data:{email_user: string, month: number})=> Promise<boolean>
    @remittance.Getter(RemittanceMethods.getters.GET_REMITTANCES_QUANTITY_BY_MONTH)
    remittanceQuantityByMonth!: RemittanceAcum[]    
    @remittance.Getter(RemittanceMethods.getters.GET_PRE_REMITTANCE)
    preRemittance!: PreRemittance;
    @remittance.Action(RemittanceMethods.actions.CANCEL_PRE_REMITTANCE)
    cancelPreRemitance!:(pre_remittance_id: number)=> Promise<boolean>
    /* NOTIFICATIONS */
    @notifications.Getter(NotificationsMethods.getters.GET_MARQUEE_NOTIFICATIONS)
    marqueeNotifications!: Notif[]
    /* BENEFICIARIES */
    @beneficiaries.Action(BeneficiariesMethods.actions.FETCH_FREQUENT_BENEFICIARIES)
    fetchFrequentBeneficiaries!: (emailObj: any) => Promise<boolean>
    @beneficiaries.Getter(BeneficiariesMethods.getters.GET_FREQUENT_BENEFICIARIES)
    frequentBeneficiaries!: FrequentBeneficiary[]
    @beneficiaries.Action(BeneficiariesMethods.actions.FETCH_TOP_FREQUENT_BENEFICIARIES)
    fetchTopFrequentBeneficiaries!: (email_user: string) => Promise<boolean>
    @beneficiaries.Getter(BeneficiariesMethods.getters.GET_TOP_FREQUENT_BENEFICIARIES)
    topFrequentBeneficiaries!: TopFrequentBeneficiary[]
    /* COUNTRIES */
    @countries.Action(CountriesMethods.actions.FETCH_ORIGN_DEST_COUNTRY_CURRENCY)
    fetchCountriesCurrencies!:(email_user: string)=>Promise<boolean>
    @countries.Getter(CountriesMethods.getters.GET_USER_ORIGIN_REMITTANCES_COUNTRY_CURRENCY)
    userRemittancesCountriesCurrencies!: CountryCurrency[]
    @countries.Action(CountriesMethods.actions.FETCH_FREQUENTS_DESTINATIONS)
    fetchFrequentDestinys!: (email_user: string) => Promise<boolean>
    @countries.Getter(CountriesMethods.getters.GET_FREQUENTS_DESTINATIONS)
    frequentDestinations!: Country[];
    /* Currencies */
    @currencies.Action(CurrenciesMethods.actions.FETCH_TOTAL_SENT_BY_CURRENCIES)
    fetchTotalSentByCurrency!:(email_user: string)=>Promise<boolean>
    @currencies.Getter(CurrenciesMethods.getters.GET_TOTAL_SENT_BY_CURRENCIES)
    totalSentByCurrencies!: CountryCurrency[]
    /* RATES */
    @rates.Action(RatesMethods.actions.FETCH_RATES_TAKEN_ADVANTAGE_OF)
    fetchRatesTakenAdvantageOf!:(email_user: string) => Promise<boolean>
    @rates.Getter(RatesMethods.getters.GET_RATES_TAKEN_ADVANTAGE_OF)
    ratesTakenAdvantageOf!: {rate_name: string, total: number}[]
}
