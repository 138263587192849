export const MONTHS={
    JANUARY:{
        monthIndex: 1,
        spanishName: 'Enero',
        spanishAcronym: 'ENE'
    },
    FEBRUARY:{
        monthIndex: 2,
        spanishName: 'Febrero',
        spanishAcronym: 'FEB'
        },
    MARCH:{
        monthIndex: 3,
        spanishName: 'Marzo',
        spanishAcronym: 'MAR'
        },
    APRIL:{
        monthIndex: 4,
        spanishName: 'Abril',
        spanishAcronym: 'ABR'
        },
    MAY:{
        monthIndex: 5,
        spanishName: 'Mayo',
        spanishAcronym: 'MAY'
        },
    JUNE:{
        monthIndex: 6,
        spanishName: 'Junio',
        spanishAcronym: 'JUN'
        },
    JULY:{
        monthIndex: 7,
        spanishName: 'Julio',
        spanishAcronym: 'JUL'
        },
    AUGUST:{
        monthIndex: 8,
        spanishName: 'Agosto',
        spanishAcronym: 'AGO'
        },
    SEPTEMBER:{
        monthIndex: 9,
        spanishName: 'Septiembre',
        spanishAcronym: 'SEP'
        },
    OCTOBER:{
        monthIndex: 10,
        spanishName: 'Octubre',
        spanishAcronym: 'OCT'
        },
    NOVEMBER:{
        monthIndex: 11,
        spanishName: 'Noviembre',
        spanishAcronym: 'NOV'
        },
    DECEMBER:{
         monthIndex: 12,
         spanishName: 'Diciembre',
         spanishAcronym: 'DIC'
    }
}

export const MONTHS_VALUES_ARRAY = Object.values(MONTHS)